export default [
  [
    {text: 'Most people living with avoidable blindness live in low-to-middle income countries', delay: 0},
    {text: 'such as Ethiopia where the leading causes of blindness which', delay: 6},
    {text: 'requires surgery are cataracts, and trachoma.', delay: 10.4},
    {text: '', delay: 14},
    {text: 'Most of these cases are found in rural communities with limited access to eye healthcare.', delay: 15.5},
    {text: 'Thanks to your support, Orbis trains and equips local eye care teams to perform sight saving surgeries,', delay: 22.7},
    {text: 'let us introduce you to the stories of some of these eye care workers and the patients they have treated', delay: 31.5},   
    {text: '', delay: 38},
  ],
  [
    {text: 'Gebre is a 55 year old fabric seller who suffered with cataracts', delay: 0},
    {text: 'until he had surgery at a health centre supported by Orbis.', delay: 5.4},
    {text: '', delay: 9.2},
    {text: 'When my eyesight got worse, it restricted me from working, I was shocked', delay: 10},
    {text: 'and got worried about the family’s financial situation.', delay: 15},   
    {text: 'So, when I heard about the service being provided at this health centre,', delay: 18.4},
    {text: 'I did not think twice. I needed to come here to find a solution. I can see clearly now.', delay: 23},  
    {text: '', delay: 30},  
  ],
  [
    {text: 'Tsehay is an eye care worker who has done more than 2,000', delay: 0},
    {text: 'trichiasis surgeries in the seven years since her training.', delay: 4.2},
    {text: '', delay: 8.4},
    {text: 'My patient said, “I can’t work and I’m isolated from my community.”', delay: 9.0},
    {text: 'We brought him here and did his trichiasis surgery successfully.', delay: 15},   
    {text: 'Afterwards, he started working, he got married. We still keep in touch,', delay: 20},
    {text: 'he tells me because of my help with surgery he’s able to be a healthy person.', delay: 26},  
    {text: '', delay: 32},  
  ],
  [
    {text: 'Aylito is a mum to seven children, she lost one eye', delay: 0},
    {text: 'to injury as a child and suffered with trichiasis in the other eye.', delay: 4.8},
    {text: '', delay: 10.2},
    {text: 'I used to struggle with my vision before I had a surgery with the help of Orbis.  It started with some pain,', delay: 11},
    {text: 'then the pain became severe like a sharp object was planted inside my eye.', delay: 19},   
    {text: 'I found it very hard to do any of the household chores with a constant pain in my eye.', delay: 26},  
    {text: '', delay: 34},  
  ],
  [
    {text: 'Dr Abeba is a glaucoma specialist and associate professor of ophthalmology', delay: 0},
    {text: 'at Addis Ababa University who has been working with Orbis for 15 years.', delay: 7},
    {text: '', delay: 12.6},
    {text: 'We used to do extra capsular cataract surgery.', delay: 13.6},
    {text: 'But I was one of the Orbis trainees on manual small incision cataract surgery', delay: 17.6},
    {text: 'and I have been practising this surgery since then. Not only practising,', delay: 24.8},   
    {text: 'I have been teaching all my residents this technique, so the impact is huge.', delay: 30.7},  
    {text: '', delay: 38},  
  ]
]
